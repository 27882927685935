* {
  box-sizing: border-box;
}

:root {
  --bg-color: #fafafa;
  --font-color: #141414;
  --highlight-color: #ede33b;
  --border-radius: calc(100vw / 40);
  --border-width: 1px;
  --hover-transition: color 150ms ease-in-out, background-color 150ms ease-in-out, fill 150ms ease-in-out;
  --margin: calc(100vw / 64);
  --font-size-large: calc(100vw / (320 / 17));
  --font-size: calc(100vw / (320 / 14));
  --line-height: 1.2;
}

::selection {
  background: var(--highlight-color);
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 1440px;
  padding: var(--margin);
  padding-bottom: 0;
  margin: 0 auto;
  background: var(--bg-color);
  color: var(--font-color);
  font-family: Tendenz, Arial, Helvetica, sans-serif;
  font-size: var(--font-size);
  font-weight: normal;
  line-height: var(--line-height);
  letter-spacing: 0.03em;
}

main {
  flex: 1;
}

img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: var(--border-radius);
  border: none;
}

ul {
  margin: 0;
  padding: 0;
}

ul.button-list > li {
  list-style: none;
}

ul.button-list > li:not(:last-of-type) {
  margin-bottom: var(--margin);
}

/* TYPO */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 1em;
  letter-spacing: 0.15em;
}

p {
  margin: 0;
  padding: 0;
  text-indent: 5ch;
}

a {
  color: inherit;
  text-decoration: none;
  transition: var(--hover-transition);
}

/* Links in paragraphs should be visually discernible */
p a {
  color: var(--highlight-color);
}

p a:hover {
  color: inherit;
}

strong,
b {
  font-weight: 400;
}

.md li {
  margin-left: 5ch;
}

a:focus {
  color: var(--highlight-color);
  text-decoration: none;
  outline: none;
}

@media (hover: hover) {
  a:hover {
    color: var(--highlight-color);
    text-decoration: none;
  }
}

*:focus:active,
*:focus:hover {
  outline: none;
}

input,
input[type="text"],
input[type="email"],
input[type="tel"],
textarea,
select {
  appearance: none;
  width: 100%;
  color: var(--highlight-color);
  background-color: transparent;
  border: var(--border-width) solid var(--highlight-color);
  border-radius: var(--border-radius);
  text-transform: uppercase;
  padding: 6px;
  text-align: center;
  font-size: var(--font-size-large);
  line-height: var(--line-height);
}

textarea {
  text-align: left;
}

input:focus,
textarea:focus {
  outline: none;
}

input:disabled {
  background-color: transparent;
}

input::placeholder {
  /* color: var(--highlight-color); */
  transition: var(--hover-transition);
  letter-spacing: 0.1em;
  line-height: var(--line-height);
}

::-moz-placeholder {
  /* color: var(--highlight-color); */
  transition: var(--hover-transition);
  letter-spacing: 0.1em;
  line-height: var(--line-height);
}

::-webkit-input-placeholder {
  /* color: var(--highlight-color); */
  transition: var(--hover-transition);
  letter-spacing: 0.1em;
  line-height: var(--line-height);
}

textarea:focus::placeholder,
input:focus::placeholder {
  color: transparent;
}

/* HELPERS */

/* https://css-tricks.com/inclusively-hidden/ */
.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/* BUTTONS */

button {
  border: none;
  cursor: pointer;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: var(--border-radius);
  text-transform: uppercase;
  transition: var(--hover-transition);
  padding: 6px;
  text-align: center;
  font-size: var(--font-size-large);
  cursor: pointer;
  border-width: var(--border-width);
  border-style: solid;
  letter-spacing: 0.1em;
}

.btn:focus {
  outline: none;
}

.btn:disabled {
  opacity: 0.75;
  cursor: default;
}

.btn-light {
  color: var(--font-color);
  background-color: var(--bg-color);
  border-color: var(--font-color);
}

.btn-dark {
  color: var(--bg-color);
  background-color: var(--font-color);
  border-color: var(--font-color);
}

.btn-cta {
  color: var(--bg-color);
  background-color: var(--highlight-color);
  border-color: var(--highlight-color);
}

.btn-cta:focus {
  background-color: transparent;
  color: var(--highlight-color);
  text-decoration: none;
}

.btn-light:focus {
  color: var(--bg-color);
  background-color: var(--font-color);
}

.btn-dark:focus {
  color: var(--font-color);
  background-color: var(--bg-color);
}

@media (hover: hover) {
  .btn-cta:not(:disabled):hover {
    background-color: transparent;
    color: var(--highlight-color);
    text-decoration: none;
  }

  .btn-light:not(:disabled):hover {
    color: var(--bg-color);
    background-color: var(--font-color);
  }

  .btn-dark:not(:disabled):hover {
    color: var(--font-color);
    background-color: var(--bg-color);
  }
}

/* COMPONENTS */

.card {
  background-color: var(--font-color);
  color: var(--bg-color);
  padding: var(--margin);
  border-radius: var(--border-radius);
  font-size: var(--font-size);
}

.hero-image {
  height: calc(100vw * 1.2);
  object-fit: cover;
}

.icon {
  height: calc(1em * var(--line-height));
  padding: 2px;
  width: auto;
  pointer-events: none;
  user-select: none;
}

.youtube-iframe-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin: var(--margin) 0;
}

.youtube-iframe {
  position: absolute;
  top: 0;
  left: 0;
  max-width: none;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
}

/* @media screen and (prefers-color-scheme: dark) {
  :root {
    --bg-color: #141414;
    --font-color: #fafafa;
    --highlight-color: #fa96a0;
  }
} */

@media screen and (min-width: 768px) {
  :root {
    --margin: calc(100vw / 96);
    --font-size-large: calc(100vw / (768 / 20.8));
    --font-size: calc(100vw / (768 / 14.4));
    --border-radius: calc(100vw / 120);
    --border-width: 2px;
    --line-height: 1.4;
  }

  body {
    line-height: var(--line-height);
    padding: calc(100vw / 30);
    padding-bottom: 0;
    letter-spacing: 0.01em;
  }

  p {
    text-indent: 3ch;
  }

  .md li {
    margin-left: 3ch;
  }

  .hero-image {
    height: calc(100vw * 0.576);
  }

  input,
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea,
  .btn {
    border-width: var(--border-width);
  }

  .btn {
    letter-spacing: initial;
    line-height: var(--line-height);
  }

  ::-moz-placeholder {
    letter-spacing: initial;
  }

  ::-webkit-input-placeholder {
    letter-spacing: initial;
  }
}

@media screen and (min-width: 1440px) {
  :root {
    --margin: 15px;
    --font-size-large: 39px;
    --font-size: 27px;
    --border-radius: 12px;
    --border-width: 3px;
  }

  body {
    padding: 45px;
    padding-bottom: 0;
  }

  .hero-image {
    height: 830px;
  }

  input,
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea,
  .btn {
    border-width: var(--border-width);
  }
}
